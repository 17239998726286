import mainjson from './main';
export default function GetData() {
        return {
        "apitype":"campaigns",
        "id":"campaign_id",
        "subid":"campaign_id ",
        "subidname":"campaign_name",
        "options":["edit","insert","delete"],
        "nameSingle":"campaign",
        "nameMultiple":"campaigns",
        "order":"campaign_id",
        "extraoptions":[
            {
                "name":"Codes",
                "page":"/codes",
            },
            {
                "name":"Unplotted Points",
                "page":"/unplottedpoints",
            },
            {
                "name":"Users",
                "page":"/campaign_users",
            }
        ],

        "fields":{
            "field1":{
                "name":"Name",
                "field":"campaign_name",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Start date",
                "field":"campaign_startdate",
                "type":"DateInput",
                "list":true,
                "required":true,
            },
            "field3":{
                "name":"End date",
                "field":"campaign_enddate",
                "type":"DateInput",
                "list":true,
                "required":true,
            },
            "field4":{
                "name":"Free driving days",
                "field":"campaign_days",
                "type":"TextInput",
                "subtype":"Number",
                "required":true,
                "list":true,
                "max":1000
            },
            "field5":{
                "name":"Welcome message",
                "field":"campaign_welcome_msg",
                "type":"TextArea",
                "required":true,
                "list":false,
            },
            "field6":{
                "name":"Goodbye message",
                "field":"campaign_goodbye_msg",
                "type":"TextArea",
                "required":true,
                "list":false,
            },
            "field7":{
                "name":"Active",
                "field":"campaign_active",
                "type":"DropDown",
                "options": {
                    "0":"inactive",
                    "1":"active",
                },
                "required":true,
                "list":true

            },   
            "field8":{
                "name":"Themes in campaign",
                "field":"themes",
                "type":"Transfer",
                "options": "themes",
                "required":false,
                "list":false,
                "multiple":true,
            }, 
            "field9":{
                "name":"Stats",
                "field":"stats",
                "type":"Display",
                "edit":false,
                "list":true,
            },   
        }
    }
    
}